import { Box, Text } from "@chakra-ui/react";
import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import { LANDING_PATHS } from "src/constant/path";
import FormStepsProvider from "src/context/FormSteps";
// import GetLocationProvider from "src/context/getLocation";
import ErrorBoundary from "src/errorboundary";

// Landing pages
const HomePage = lazy(() => import("src/modules/Landing/pages/Home"));
const ContactPage = lazy(
  () => import("src/modules/Landing/pages/Home/Contact")
);

const LandingRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route
          path={LANDING_PATHS.HOME}
          element={
            <ErrorBoundary>
              <FormStepsProvider>
                <HomePage />
              </FormStepsProvider>
            </ErrorBoundary>
          }
        />
        <Route path={LANDING_PATHS.ABOUT} element={<p>About Page</p>} />
        <Route
          path={LANDING_PATHS.CREATE_ACCOUNT}
          element={
            <Box textAlign={"center"} mt={10} fontSize={"18px"}>
              Coming Soon
            </Box>
          }
        />
        <Route
          path={LANDING_PATHS.CONTACT}
          element={
            <ErrorBoundary>
              <FormStepsProvider>
                <ContactPage />
              </FormStepsProvider>
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <Box>
              <Text as="h2">Oopppss, you seem to be lost</Text>
            </Box>
          }
        />
      </Route>
    </Routes>
  );
};

export default LandingRouter;
