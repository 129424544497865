import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import "../App.css";

import Routes from "src/Routes";

const App: FunctionComponent = () => {
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  return (
    <>
      <Routes />
      <Outlet />
    </>
  );
};

export default App;
