import { ReactElement } from "react";

import { Navigate, useLocation } from "react-router-dom";

type Props = {
  children: ReactElement;
};

const PrivateRoute = ({ children }: Props) => {
  const location = useLocation();

  const isLoggedIn = false;

  if (!isLoggedIn) {
    return <Navigate to={`/`} state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default PrivateRoute;
