// setup color to preference


export const colors = {
  primary: "#0B0A0A",
  secondary: "#F6F3FF",
  dark: "#1A1A1A",
  danger: "#F94144",
  warning: "#F8961E",
  info: "#05CD99",
  tertiary: "#CF2CA0",
};
