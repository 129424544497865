import { FunctionComponent } from "react";
import {
  Routes as BrowserRoutes,
  BrowserRouter,
  Route,
} from "react-router-dom";

import { BASE_ROUTES } from "src/constant/baseRoute";

// import ErrorBoundary from "src/errorboundary";

import PrivateRoute from "src/Routes/PrivateRoute";

import { Box, Text } from "@chakra-ui/react";

const Routes: FunctionComponent<Record<string, never>> = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        {BASE_ROUTES.map(({ useAuth, path, Component, Layout, sidenav }) =>
          useAuth && Layout ? (
            <Route
              path={path}
              key={path}
              element={
                <PrivateRoute>
                  <Layout sidenav={sidenav}>
                    <Component />
                  </Layout>
                </PrivateRoute>
              }
            />
          ) : (
            <Route path={path} key={path} element={<Component />} />
          )
        )}
        <Route
          path="*"
          element={
            <Box
              sx={{
                width: "50%",
                margin: "5em auto",
                textAlign: "center",
              }}
            >
              <Text variant="h1">Oopppss, you seem to be lost</Text>
            </Box>
          }
        />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
