import { Box, Text } from "@chakra-ui/react";
import { FunctionComponent, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { APP_PATHS } from "src/constant/path";
import ErrorBoundary from "src/errorboundary";

// App pages
const Dashboard = lazy(() => import("src/modules/MainApp/pages/Dashboard"));

const AppRouter: FunctionComponent<Record<string, never>> = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Navigate to="dashboard" replace />} />
        <Route
          path={APP_PATHS.DASHBOARD}
          element={
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          }
        />

        <Route
          path="*"
          element={
            <Box sx={{ width: "50%", margin: "5em auto", textAlign: "center" }}>
              <Text variant="h1">Oopppss, you seem to be lost</Text>
            </Box>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
