type BASE_PATHS_PROPS = {
  APP: string;
  AUTH: string;
};

type APP_PATHS_PROPS = {
  DASHBOARD: string
};

type LANDING_PATHS_PROPS = {
  HOME: string,
  ABOUT: string,
  CREATE_ACCOUNT: string
  CONTACT: string
};

type AUTH_PATHS_PROPS = {
  SIGNIN: string;
  SIGNUP: string;
  FORGOT_PASSWORD: string;
  RESET_PASSWORD: string;
  VERIFY_EMAIL: string;
  CONFIRM_EMAIL: string;
};

export const BASE_PATHS: BASE_PATHS_PROPS = {
  APP: "app",
  AUTH: 'auth',
};

export const AUTH_PATHS: AUTH_PATHS_PROPS = {
  SIGNIN: "signin",
  SIGNUP: 'signup',
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  VERIFY_EMAIL: "verify-email",
  CONFIRM_EMAIL: "confirm-email",
};


export const LANDING_PATHS: LANDING_PATHS_PROPS = {
  HOME: '/',
  ABOUT: 'about',
  CREATE_ACCOUNT: 'create-account',
  CONTACT: 'contact'
}


export const APP_PATHS: APP_PATHS_PROPS = {
  DASHBOARD: 'dashboard',
};
