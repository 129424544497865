export const getAccessToken = () => {
  return localStorage.getItem("clu");
};

export const getUserData = () => {
  return localStorage.getItem("cld");
};

export const getDecodedToken = () => {
  try {
    const token = getAccessToken() || "";

    return token;
  } catch (error) {
    console.log(error);
  }
};

export const getDecodedData = () => {
  try {
    const data = getUserData() || "";

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const isAuthenticated = () => {
  try {
    const decodeToken = getDecodedToken();
    if (decodeToken) {
      return true;
    } else return false;
  } catch (error) {
    return false;
  }
};
