import {
  useMutation,
  // useQuery
} from "@tanstack/react-query";
import {
  createContext,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import Api from "src/config";

export const FormStepsContext = createContext(null);

type Props = {
  children: any;
};

const FormStepsProvider: FunctionComponent<Props> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isToggleEnrollFormModal, setIsToggleEnrollFormModal] = useState(false);
  const [isToggleTrackIdFormModal, setIsToggleTrackIdFormModal] =
    useState(false);
  const [isToggleComingSoonModal, setIsToggleComingSoonModal] = useState(false);
  const [isToggleContinueFormModal, setIsToggleContinueFormModal] =
    useState(false);
  const [id, setId] = useState("");
  const [formFields, setFormFields] = useState<any>({
    first_name: "",
    last_name: "",
    middle_name: "",
    father_surname: "",
    father_first_name: "",
    mother_first_name: "",
    mother_surname: "",
    country_residence: "",
    country_enrollment: "",
    age_grade: "",
    phone_number: "",
    email: "",
    payment_method: "",
    capturing_country: "",
    capturing_state: "",
    capturing_location: "",
    step_number: "",
    id: "",
    nationality: "",
    capturing_date: null,
    capturing_time: null,
    new_user: null,
    gender: "",
    redirect_url: "",
    document_type: "",
    document_instance: "",
    guardian_first_name: "",
    guardian_middle_name: "",
    guardian_surname: "",
    guardian_nin: "",
    next_of_kin_first_name: "",
    next_of_kin_middle_name: "",
    next_of_kin_surname: "",
    next_of_kin_nin: "",
    guardian_relationship: "",
    payment_type_choices: [],
    documentTypeName: "",
    document_saved: null,
    lga_residence: null,
    capturing_county: "",
  });

  // const { data, isFetching } = useQuery({
  //   queryKey: ["form_steps"],
  //   staleTime: 30000,
  //   queryFn: async () => {
  //     const {
  //       data: { data },
  //     } = await Api.get(
  //       `${process.env.REACT_APP_CLIENT_URL}/form_steps_data.json`
  //     );

  //     return data;
  //   },
  // });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (payload) => {
      const data = await Api.post(
        `${process.env.REACT_APP_BASE_URL}identity/nin/applications/document/upload`,
        payload
      );
      return data;
    },
    onError: (err) => {
      throw err;
    },
  });

  const { mutateAsync: mutateAsyncEmail, isPending: isPendingEmail } =
    useMutation({
      mutationFn: async (payload) => {
        const data = await Api.post(
          `${process.env.REACT_APP_BASE_URL}identity/nin/enrollment/getCode`,
          payload
        );
        return data;
      },
      onError: (err) => {
        throw err;
      },
    });

  const { mutateAsync: mutateAsyncUpdate, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: async ({ id, payload }: any) => {
        const data = await Api.put(
          `${process.env.REACT_APP_BASE_URL}identity/nin/applications/${id}`,
          payload
        );
        return data;
      },
      onError: (err) => {
        throw err;
      },
    });

  const {
    mutateAsync: mutateAsyncContinueRegistration,
    isPending: isPendingContinueRegistration,
  } = useMutation({
    mutationFn: async (payload) => {
      const data = await Api.post(
        `${process.env.REACT_APP_BASE_URL}identity/validate-code`,
        payload
      );
      return data;
    },
    onError: (err) => {
      throw err;
    },
  });

  const { mutateAsync: mutateAsyncWaitlist, isPending: isPendingWaitingList } =
    useMutation({
      mutationFn: async (payload) => {
        const data = await Api.post(
          `${process.env.REACT_APP_BASE_URL}waitlist`,
          payload
        );
        return data;
      },
      onError: (err) => {
        throw err;
      },
    });

  const { mutateAsync: mutateAsyncSubmission, isPending: isPendingSubmission } =
    useMutation({
      mutationFn: async (payload) => {
        const data = await Api.post(
          `${process.env.REACT_APP_BASE_URL}identity/nin/applications/local-submission`,
          payload
        );
        return data;
      },
      onError: (err) => {
        throw err;
      },
    });

  const handleNextStep = useCallback(() => {
    setCurrentStep((prev: number) => prev + 1);
  }, []);

  const handleWaitingList = useCallback(() => {
    setCurrentStep(100);
  }, []);

  const handlePrevStep = useCallback(() => {
    // if (
    //   formFields["age_grade"] === "16+" &&
    //   currentStep === 9
    // ) {
    //   setCurrentStep((prev: number) => prev - 2);
    // } else {
    setCurrentStep((prev: number) => prev - 1);
    // }
  }, [formFields]);

  const handleToggleEnrolFormModal = useCallback(
    (step = 0) => {
      setIsToggleEnrollFormModal(!isToggleEnrollFormModal);
      setCurrentStep(step);
    },
    [isToggleEnrollFormModal]
  );

  const handleToggleTrackModal = useCallback(() => {
    setIsToggleTrackIdFormModal(!isToggleTrackIdFormModal);
  }, [isToggleTrackIdFormModal]);

  const handleToggleComingSoonModal = useCallback(() => {
    setIsToggleComingSoonModal(!isToggleComingSoonModal);
  }, [isToggleComingSoonModal]);

  const handleToggleContinueModal = useCallback(() => {
    setIsToggleContinueFormModal(!isToggleContinueFormModal);
  }, [isToggleContinueFormModal]);

  const contextValue = useMemo(
    () => ({
      // isFetching,
      mutateAsync,
      isPending,
      mutateAsyncEmail,
      isPendingEmail,
      mutateAsyncUpdate,
      isPendingUpdate,
      mutateAsyncContinueRegistration,
      isPendingContinueRegistration,
      currentStep,
      id,
      setId,
      isToggleEnrollFormModal,
      isToggleTrackIdFormModal,
      isToggleContinueFormModal,
      handleNextStep,
      handlePrevStep,
      handleWaitingList,
      handleToggleEnrolFormModal,
      handleToggleTrackModal,
      handleToggleContinueModal,
      setFormFields,
      formFields,
      setCurrentStep,
      mutateAsyncWaitlist,
      isPendingWaitingList,
      mutateAsyncSubmission,
      isPendingSubmission,
      isToggleComingSoonModal,
      handleToggleComingSoonModal,
      // data,
    }),
    [
      // isFetching,
      mutateAsync,
      isPending,
      mutateAsyncEmail,
      isPendingEmail,
      mutateAsyncUpdate,
      isPendingUpdate,
      mutateAsyncContinueRegistration,
      isPendingContinueRegistration,
      // data,
      currentStep,
      id,
      setId,
      isToggleEnrollFormModal,
      isToggleTrackIdFormModal,
      isToggleContinueFormModal,
      handleToggleEnrolFormModal,
      handleToggleTrackModal,
      handleToggleContinueModal,
      handleNextStep,
      handlePrevStep,
      handleWaitingList,
      setFormFields,
      formFields,
      setCurrentStep,
      mutateAsyncWaitlist,
      isPendingWaitingList,
      mutateAsyncSubmission,
      isPendingSubmission,
      isToggleComingSoonModal,
      handleToggleComingSoonModal,
    ]
  );

  return (
    <FormStepsContext.Provider value={contextValue}>
      {children}
    </FormStepsContext.Provider>
  );
};

export default FormStepsProvider;
